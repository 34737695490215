.hero-background {
  width: 100vw;
  height: 94vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);

  text-align: center;

  /*Text Color*/
  color: #fff;
}

.hero-background h1 {
  font-size: 4em;

  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.img-icon {
  border-radius: 50%;
}

.social-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 40px 100px;
}

.linkedin {
  transition: all 0.3s ease-in;
}

.github {
  transition: all 0.3s ease-in;
}

.linkedin:hover {
  color: rgba(240, 240, 240, 0.9);
}

.github:hover {
  color: rgba(240, 240, 240, 0.9);
}

.nav-bar {
  font-size: 1.2em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 2.5em;
  padding-right: 2.5em;
}

/* A link that has not been visited */
a:link {
  color: #fff;
  text-decoration: none;
}

/* A link that has been visited */
a:visited {
  color: #fff;
  text-decoration: none;
}

/* A link that is hovered on */
a:hover {
  color: #fff;
  text-decoration: none;
}

/* A link that is selected */
a:active {
  color: #fff;
  text-decoration: none;
}
