body,
html {
  margin: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.page-headers--left {
  margin-left: 15px;
  margin-top: 10px;

  font-size: 4em;

  color: #808182;
}

.page-headers--right {
  margin-right: 15px;
  margin-top: 10px;

  font-size: 4em;
  /* text-align: end; */

  color: #808182;
}

.hero-background {
  width: 100vw;
  height: 94vh;

  display: -webkit-flex;

  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);

  text-align: center;

  /*Text Color*/
  color: #fff;
}

.hero-background h1 {
  font-size: 4em;

  margin-top: 0.2em;
  margin-bottom: 0.5em;
}

.img-icon {
  border-radius: 50%;
}

.social-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  margin: 40px 100px;
}

.linkedin {
  transition: all 0.3s ease-in;
}

.github {
  transition: all 0.3s ease-in;
}

.linkedin:hover {
  color: rgba(240, 240, 240, 0.9);
}

.github:hover {
  color: rgba(240, 240, 240, 0.9);
}

.nav-bar {
  font-size: 1.2em;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  -webkit-align-items: center;
          align-items: center;
  padding-left: 2.5em;
  padding-right: 2.5em;
}

/* A link that has not been visited */
a:link {
  color: #fff;
  text-decoration: none;
}

/* A link that has been visited */
a:visited {
  color: #fff;
  text-decoration: none;
}

/* A link that is hovered on */
a:hover {
  color: #fff;
  text-decoration: none;
}

/* A link that is selected */
a:active {
  color: #fff;
  text-decoration: none;
}

#desktop-nav {
  width: 100%;
  margin: 0;
  top: 0;
  background: #1391ff;
  color: #fff;
  z-index: 9999;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

ul {
  list-style: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;

  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

li:hover {
  background: #4ba8ff;
  color: #fff;
}

.fixed-nav {
  position: fixed;
}

h3 {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  #desktop-nav {
    display: none;
  }
}

.project-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 3vw, 100% 0%, 100% 100%, 0% 100%); */
}

.resume-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 100%); */
}

.about-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 3vw, 100% 0%, 100% 100%, 0% 100%); */
}

.contact-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 100%); */
}

.main-content {
  font-size: 1.3em;
  color: #fff;
}

#mobile-nav {
  margin: 0;
  top: 0;
  color: #fff;
  z-index: 9999;
  padding: 15px 15px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  box-sizing: border-box;
  text-align: end;
  width: 100%;
}

#icon {
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  cursor: pointer;
}

#icon:hover {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (min-width: 769px) {
  #mobile-nav {
    display: none;
  }
}

