body,
html {
  margin: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.page-headers--left {
  margin-left: 15px;
  margin-top: 10px;

  font-size: 4em;

  color: #808182;
}

.page-headers--right {
  margin-right: 15px;
  margin-top: 10px;

  font-size: 4em;
  /* text-align: end; */

  color: #808182;
}
