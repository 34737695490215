.project-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 3vw, 100% 0%, 100% 100%, 0% 100%); */
}

.resume-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 100%); */
}

.about-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 3vw, 100% 0%, 100% 100%, 0% 100%); */
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;

  background-image: linear-gradient(180deg, #4ba8ff 35%, #1391ff 100%);
  /* clip-path: polygon(0 0, 100% 3vw, 100% 100%, 0 100%); */
}

.main-content {
  font-size: 1.3em;
  color: #fff;
}
