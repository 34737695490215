#desktop-nav {
  width: 100%;
  margin: 0;
  top: 0;
  background: #1391ff;
  color: #fff;
  z-index: 9999;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.2);
}

ul {
  list-style: none;
  display: flex;
  flex-direction: row;

  margin: 0;
  padding: 0;
}

li {
  height: 100%;
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

li:hover {
  background: #4ba8ff;
  color: #fff;
}

.fixed-nav {
  position: fixed;
}

h3 {
  font-size: 1.5em;
}

@media (max-width: 768px) {
  #desktop-nav {
    display: none;
  }
}
