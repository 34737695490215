#mobile-nav {
  margin: 0;
  top: 0;
  color: #fff;
  z-index: 9999;
  padding: 15px 15px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  box-sizing: border-box;
  text-align: end;
  width: 100%;
}

#icon {
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

#icon:hover {
  transform: rotate(90deg);
}

@media (min-width: 769px) {
  #mobile-nav {
    display: none;
  }
}
